import React from 'react';
import Layout from "../../components/layout";

const Kilcoy = () => {
    return (
        <Layout>
            <h1>Kilcoy</h1>
            <p>Instructor</p>
            <a href="/about/SenseiRavey">Sensei Graham Ravey</a> - TOGKA World Chief Instructor

            <p>Private lessons by arrangement. <a href="/Contact">Contact us</a> for the details.</p>
        </Layout>);
}

export default Kilcoy;
